import * as React from "react";
import * as ReactDOM from "react-dom";
import { Brewery } from "../components/Brewery";
import { BreweryModel } from "../models/BreweryModel";

interface IProps {
	breweryModel: BreweryModel;
	changeParentState:(state: object) => void;
}

interface IState {
	nearestBrewery: IBrewery;
	searchLocation: string;
	myCoordinates: Coordinates;
	hasGeoLocation: boolean;
	locationAvailable: boolean;
}

export class BreweryFinder extends React.Component<IProps,IState> {
	constructor(props: any) {
		super(props);
		let myCoordinates: Coordinates = {longitude: 4.80926, latitude: 52.34399,
		accuracy: null, altitude: null, altitudeAccuracy: null, heading: null, speed: null};

		this.handleChange = this.handleChange.bind(this);
		this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
		this.handleFindLocation = this.handleFindLocation.bind(this);
		let hasGeoLocation: boolean = false;
		if (navigator.geolocation) {
			hasGeoLocation= true;
		}
		this.state = {nearestBrewery: null, searchLocation: "", myCoordinates: myCoordinates, hasGeoLocation: hasGeoLocation,
			locationAvailable: true};
    }

    componentDidMount(): void {
        let state: object = {pageTitle: "Brewery Finder", navigationString: "finder"};
        this.props.changeParentState(state);
    }

	handleChange(event: React.FormEvent<HTMLInputElement>): void {
		this.setState({searchLocation: (event.target as HTMLInputElement).value});
	}

	async handleSearchSubmit(event: React.FormEvent<HTMLFormElement>): Promise<void> {
		event.preventDefault();
		if(this.state.searchLocation==="") {
			await this.setState({searchLocation: "Stadhouderskade 160, 1074BC, Amsterdam"});
		}
		if(this.state.searchLocation === "My current location") {
			this.handleFindLocation(null);
		} else {
			this.props.breweryModel.getCoordinatesOfLocation(this.state.searchLocation).then((myCoordinates) => {
				this.setState({myCoordinates: myCoordinates});
				this.getNearestBrewery();
			});
		}
	}

	handleFindLocation(event: React.MouseEvent<HTMLInputElement, MouseEvent>): void {
		this.setState({searchLocation: "My current location"});
		navigator.geolocation.getCurrentPosition(position => {
			this.setState({myCoordinates: position.coords});
			this.getNearestBrewery();
		}, () => { this.setState({locationAvailable: false}); });
	}

	getNearestBrewery(): void {
		if(typeof this.state.myCoordinates.latitude === "undefined" || typeof this.state.myCoordinates.longitude === "undefined") {
			this.setState({locationAvailable: false});
		} else {
			this.setState({locationAvailable: true});
			this.props.breweryModel.ready().then(() => {
				this.setState({nearestBrewery: this.props.breweryModel.getNearestBrewery(this.state.myCoordinates)});
			}).catch(e => {
				console.log(e);
			});
		}
	}

	render(): JSX.Element {
		try {
			return <div>
				<form onSubmit={this.handleSearchSubmit}>
					<label htmlFor="searchLocation">Zipcode or address:</label><br/>
					<input type="text" id="searchLocation" value={this.state.searchLocation} onChange={this.handleChange}
						placeholder="Stadhouderskade 160, 1074BC, Amsterdam"/><br/>
					<input type="button" onClick={this.handleFindLocation} value="Find my location"/>
					<input type="submit" value="Search" />
				</form>
				{this.state.nearestBrewery !== null && this.state.locationAvailable?
					<Brewery brewery={this.state.nearestBrewery} showBeers={true} showURL={false}/>
				:
					(!this.state.locationAvailable? <p>Location cannot be determined</p> :"")
				}
			</div>;
		} catch(e) {
			return <></>;
		}
	}
}