import * as React from "react";
import * as ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Link, Redirect } from "react-router-dom";
import { BreweryFinder } from "./components/BreweryFinder";
import { BreweryBrowser } from "./components/BreweryBrowser";
import { BreweryDetails } from "./components/BreweryDetails";
import { BreweryModel } from "./models/BreweryModel";
declare let module: any;


export class App extends React.Component<{},{pageTitle: string, navigationString: string, menuopen: boolean}> {
	breweryModel: BreweryModel;
	constructor(props: any) {
		super(props);
		this.breweryModel = new BreweryModel();
		this.state = {pageTitle: "Brewery Finder", navigationString: "finder", menuopen: false};
		this.handleToggleMenu = this.handleToggleMenu.bind(this);
	}

	changeParentState(state: object): void {
		this.setState(state);
		this.setState({menuopen: false});
	}

	handleToggleMenu(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void {
		this.setState({menuopen: !this.state.menuopen});
		event.preventDefault();
	}

	render(): JSX.Element {
		return (<div className={"container " + ( this.state.menuopen? "menuopen": "")}>
			<div className="navigationBar">
				<img src="/images/menufoto.jpg" id="menulogo"/>
				<hr/>
				<ul className="menu">
					<li className={this.state.navigationString === "finder"? "current":""}><Link to="/finder">Brewery Finder</Link></li>
					<li className={this.state.navigationString === "browser"? "current":""}><Link to="/browser">Brewery Browser</Link></li>
				</ul>
			</div>
			<div className="page">
				<div className="header">
					<div className="menubutton"><a href="#" onClick={this.handleToggleMenu}></a></div>
					<h1>{this.state.pageTitle}</h1>
				</div>
				<div className="content">
					<Switch>
						<Route path="/finder" render={(props) => (
							<BreweryFinder breweryModel={this.breweryModel} changeParentState = {this.changeParentState.bind(this)}/>) }/>
						<Route path="/browser" render={(props) => (
							<BreweryBrowser breweryModel={this.breweryModel} changeParentState = {this.changeParentState.bind(this)} />) }/>
						<Route path="/brewery/:name" render={(props) => (
							<BreweryDetails breweryModel={this.breweryModel} brewery={this.breweryModel.getBreweryByName(props.match.params.name)}
							changeParentState = {this.changeParentState.bind(this)}/>) }/>
						<Redirect to="/finder" />
					</Switch>
				</div>
			</div>
		</div>);
	}
}

ReactDOM.render(
	(<BrowserRouter>
		<App />
	</BrowserRouter>),
	document.getElementById("root")
);

if (module.hot) {
	module.hot.accept();
}