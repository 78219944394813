import * as React from "react";
import * as ReactDOM from "react-dom";
import { Brewery } from "../components/Brewery";
import { BreweryModel } from "../models/BreweryModel";

export class BreweryBrowser extends React.Component<{breweryModel: BreweryModel, changeParentState:(state: object) => void},{}> {
	constructor(props: any) {
		super(props);
	}

    componentDidMount(): void {
        let state: object = {pageTitle: "Brewery Browser", navigationString: "browser"};
        this.props.changeParentState(state);
    }

	render(): JSX.Element {
		try {
			return <div>
				{this.props.breweryModel.breweries.map((brewery:IBrewery, index: number) => (
					    <Brewery brewery={brewery} showBeers={false} key={index} showURL={true} />
                    ))
				}
			</div>;
		} catch(e) {
			return <></>;
		}
	}
}