import * as breweriesJson from "../../data/brouwerijen.json";
import * as beersJson from "../../data/bieren.json";
import axios from "axios";

export class BreweryModel {
    public breweries: Array<IBrewery>;
    public beers: Array<IBeer>;
    private allpromises: Array<Promise<any>> = [];

    constructor() {
        this.loadBreweries(breweriesJson.breweries as Array<IBrewery>);
        this.loadBeers(beersJson.beers as Array<IBeer>);
        this.addBeersToBreweries();
    }
    public loadBreweries(breweries: Array<IBrewery>): void {
        this.breweries = breweries;
        this.breweries.forEach((brewery: IBrewery) => {
            brewery.beers = [];
            this.getCoordinatesOfLocation(brewery.address + ", " + brewery.zipcode + ", " + brewery.city)
            .then(coordinates => brewery.coordinates = coordinates).catch(e => {
                console.log(e);
            });
        });
    }

    public ready(): Promise<any> {
        return Promise.all(this.allpromises);
    }

    public loadBeers(beers: Array<IBeer>): void {
        this.beers = beers;
    }

    public addBeersToBreweries(): void {
        this.beers.forEach(beer => {
            let brewery: IBrewery | undefined = this.breweries.find(brewery => {return brewery.name === beer.brewery;});
            if(typeof brewery !== "undefined") { // has the brewery been found
                if(typeof brewery.beers.find(brewerybeer => brewerybeer.name === beer.name) === "undefined") {
                    brewery.beers.push(beer);
                }
            }
        });
    }

    public getCoordinatesOfLocation(location: string): Promise<Coordinates> {
        let promise: Promise<Coordinates> = new Promise(function(resolve: (value?: Coordinates | PromiseLike<Coordinates>) => void,
        reject: any): void {
            axios.get("./api/?s=" + location)
            .then(response => {
                try {
                    let coordinates: Coordinates = {longitude: response.data.Longitude, latitude: response.data.Latitude,
                        accuracy: null, altitude: null, altitudeAccuracy: null, heading: null, speed: null};
                    resolve(coordinates);
                } catch (e) {
                    reject(e);
                }
            });
          });
        this.allpromises.push(promise);
        return promise;
    }

    public getNearestBrewery(currentCoordinates: Coordinates): IBrewery {
        let nearestBrewery: IBrewery;
        let distance: number = Infinity;
        this.breweries.forEach(brewery => {
            let newDistance: number = this.distanceBetweenCoordinates(currentCoordinates, brewery.coordinates);
            brewery.distance = newDistance;
            if(newDistance < distance) {
                nearestBrewery = brewery;
                distance = newDistance;
            }
        });
        return nearestBrewery;
    }

    public getBreweryByName(name: string): IBrewery {
        let foundBrewery: IBrewery = this.breweries[0];
        this.breweries.forEach(brewery => {
            if(brewery.name === name) {
                foundBrewery = brewery;
            }
        });
        return foundBrewery;
    }

    public distanceBetweenCoordinates(coordinates1: Coordinates, coordinates2: Coordinates): number {
        // calculation based on haversine formula, see https://www.movable-type.co.uk/scripts/latlong.html
        let radiusearth: number = 6371e3; // in metres
        let phi1: number = this.degreesToRadians(coordinates1.latitude);
        let phi2: number = this.degreesToRadians(coordinates2.latitude);
        let differenceLatitude: number = this.degreesToRadians(coordinates2.latitude - coordinates1.latitude);
        let differenceLongitude: number = this.degreesToRadians(coordinates2.longitude - coordinates1.longitude);

        let a: number = Math.sin(differenceLatitude/2) * Math.sin(differenceLatitude/2) +
                Math.cos(phi1) * Math.cos(phi2) *
                Math.sin(differenceLongitude/2) * Math.sin(differenceLongitude/2);
        let c: number = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

        let distance: number = radiusearth * c;
        return distance;
    }

    public degreesToRadians(degrees: number): number {
        return degrees / 180 * Math.PI;
    }
}