import * as React from "react";
import * as ReactDOM from "react-dom";
import { Brewery } from "../components/Brewery";
import { BreweryModel } from "../models/BreweryModel";
import { RouteComponentProps } from "react-router-dom";

interface IProps {
    breweryModel: BreweryModel;
    brewery: IBrewery;
    changeParentState:(state: object) => void;
}

export class BreweryDetails extends React.Component<IProps, {brewery: IBrewery}> {
	constructor(props: any) {
        super(props);
	}

    componentDidMount(): void {
        let state: object = {pageTitle: "Brewery Details", navigationString: "details"};
        this.props.changeParentState(state);
    }

	render(): JSX.Element {
		try {
			return <Brewery brewery={this.props.brewery} showBeers={true} showURL={false}/>;
		} catch(e) {
			return <></>;
		}
	}
}