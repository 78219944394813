import * as React from "react";
import { Beer } from "./Beer";
import { Link } from "react-router-dom";

interface IProps {
	brewery: IBrewery;
	showBeers: boolean;
	showURL: boolean;
}

export class Brewery extends React.Component<IProps, {}> {
	render():JSX.Element {
		try {
			return <div>
				<h2>{this.props.showURL? (<Link to={"/brewery/" + this.props.brewery.name}>{this.props.brewery.name}</Link>) :
					this.props.brewery.name}</h2>
				{this.props.brewery.address}<br/>
				{this.props.brewery.zipcode}, {this.props.brewery.city}<br/>
				Open on: {this.props.brewery.open.join(", ")}<br/>
				{typeof(this.props.brewery.distance) === "number"? "Distance: " + Math.round(this.props.brewery.distance/100)/10 + " km":""}
				{this.props.showBeers?
					<div>
						<h3>Beers from this brewery:</h3>
						{this.props.brewery.beers.length===0?
							<p>There are no beers known from this brewery, please add them after buying them!</p> : ""
						}
						{this.props.brewery.beers.map((item, index) => (
							<Beer key={index} beer={item} />
						))}
					</div> : ""
				}
			</div>;
		} catch(e) {
			return <></>;
		}
	}
}