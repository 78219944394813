import * as React from "react";

interface IProps {
    beer: IBeer;
    key: number;
}

export class Beer extends React.Component<IProps, {}> {
	render():JSX.Element {
        return <div>
            <h4>{this.props.beer.name}</h4>
            Style: {this.props.beer.style}<br/>
            Alcohol: {this.props.beer.alcohol}%<br/>
            Volume: {this.props.beer.volume}<br/>
            Keg: {this.props.beer.keg}
		</div>;
	}
}